$browser-context: 16; // Default

@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em
}


//live Path
$websitePath: '/';





//Local Path
//$websitePath : '/en-sa/';



.text-justify{
  text-align: justify;
}





.eng_font{
  font-family:"univers","Helvetica Neue",Helvetica,Arial,sans-serif !important;
}
.f_num{
  font-weight: normal !important;
}

.r-hov{
  text-align: center;
  &:before, &:after{
    display: none !important;
  }
}