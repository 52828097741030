.timeLineWrapper{
  .redcircle{
    p{
      margin: 0px 0px 10px !important;
    }
  }
}
.redcircle-layer p {
  font-size: 30px !important;
}

.t-drive-cont .inner-t-drive form .form-btn-sec {
  #bookDriveBtn{
    min-width: auto;
    padding-right: 55px;
  }
}



@media (min-width: 1530px) and (max-width: 1540px){
  .revealTo {
    &.video {
      height: 350px !important;
    }
  }
}

@media (min-width: 1025px){

  .safety-section {
    .cd-image-container{
      //width: 54%;
    }
    .after-before {
      .gery-text {
        width: em(200);
        right: 100px;
      }
    }
  }

  body .main-pro-banner div .text-sec h1{
    font-size: em(68.8);
  }
}
#countryLanguageClick{
  min-width: 12.4375em;
}
.enq-form-sec .btn-white{
  min-width: 16.25em !important;
}
.filter-menu{
  .btn-primary{
    font-size: em(12);
  }
}



body {
  .btn-primary{
    min-width: 11.4375em;
  }
  .stepPopup .controls a{
    min-width: 13.25em;
  }
  .step-into-innovation .btn{
    min-width: 25.5em;
  }
  .compare-models .btn{
    min-width: 20.9375em;
  }
  .search-by-filter .box .s-filters .search-by{
    width: 12%;
  }
  .search-by-filter .box .s-filters .filters{
    width: 81%;
  }
  .search-by-filter .box .s-filters .form-btn-sec{
    width:19%;
  }
}
.productShowCaseSection .showCase .content .details .header h2{
  font-size: 1.45em !important;
}
.powerList li{
  width: 50%;
  margin-bottom: 10px;
  &:last-child{
    margin-bottom: 0;
    padding-left: 0;
    border: 0;
  }
}

.rightMenu ul li a{

  &:hover{
    width: 24em !important;
  }
  span{
    right: -14% !important;
  }
}


.wide_content h3{
  font-size: 39px !important;
  line-height: 35px !important;
}
#Specs .wide_content h4{
  line-height: 19px !important;
  font-size: 19px !important;
}

.homeslider .redcircle-layer p{
  font-size: 20px;
  line-height: 36px;
}
#rev_slider{
  .tp-leftarrow{
    width:11.2em;
    &:before{
      width:12.2em;
    }
  }

  .tp-rightarrow{
    width:12em;
    &:before{
      width:13em;
    }
  }
}
.search-result .compare.specifications .car-comparision .spec_bt .sub{
  width: 550px;
}



/*Other CSS Overwrite For Fr*/

.bottom-form{
  .btn-white{
    i{
      display: none;
    }
  }
}



@media (min-width: 768px) and (max-width: 991px){
  .bottom-form {
    .btn-white{
      span{
        font-size:em(13.6);
      }
    }
  }
}







@media (max-width: 480px){

  .convenience-section{
    .engine-thumb {
      h4{
        padding-right: 50px;
      }
    }
  }

  body{
    .search-by-filter .box .s-filters .search-by{
      width: auto;
      display: inline-block;
    }
  }
  .search-result .compare.specifications .car-comparision .spec_bt .sub{
    width: 100%;
  }

  body .compare.specifications .car-comparision .spec_bt .sub .sep{
    display: none;
  }

  body .compare.specifications .car-comparision .spec_bt .sub a.bt_1{
    margin-top: em(5);
    margin-bottom: em(5);
  }


}



/*Other CSS Overwrite For Fr*/






@media (min-width: 1280px) and (max-width: 1390px) {
  .homeslider .redcircle-layer p{
    line-height: 26px !important;
  }
}

@media (max-width: 480px){
  body{
    .explore-sec .services-box .box-three-text h3 br {
      display: none;
    }
  }
}